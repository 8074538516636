export const dump = [
    {
        "id": "1",
        "position": "0",
        "name": "example1",
        "description": "foobar",
        "scope_read": "",
        "scope_edit": "",
        "deleted": null,
        "deleted_by": "0",
        "access": "read",
        "link": "/1",
        "folders": [
            {
                "id": "1",
                "drives_id": "1",
                "position": "0",
                "name": "first",
                "parent_id": "0",
                "deleted": null,
                "deleted_by": "0",
                "link": "/1/folders/1",
                "folders": [],
                "files": [
                    {
                        "id": "1",
                        "folders_id": "1",
                        "uuid": "bbcbcbaa-7eca-4c23-90b3-1210e74b3988",
                        "deleted": null,
                        "deleted_by": "0",
                        "name": "first file",
                        "mime_type": "text/plain",
                        "version": "1",
                        "link": "/1/folders/1/files/1"
                    },
                    {
                        "id": "2",
                        "folders_id": "1",
                        "uuid": "1e039d46-c238-43be-be7a-ae54b4119e56",
                        "deleted": null,
                        "deleted_by": "0",
                        "name": "updated first file",
                        "mime_type": "text/plain",
                        "version": "1",
                        "link": "/1/folders/1/files/2"
                    }
                ]
            },
            {
                "id": "3",
                "drives_id": "1",
                "position": "0",
                "name": "second",
                "parent_id": "0",
                "deleted": null,
                "deleted_by": "0",
                "link": "/1/folders/3",
                "folders": [],
                "files": []
            },
            {
                "id": "2",
                "drives_id": "1",
                "position": "0",
                "name": "subfirst",
                "parent_id": "0",
                "deleted": null,
                "deleted_by": "0",
                "link": "/1/folders/2",
                "folders": [],
                "files": [
                    {
                        "id": "3",
                        "folders_id": "2",
                        "uuid": "4bde821a-790a-458b-979c-7fe8c1169bd9",
                        "deleted": null,
                        "deleted_by": "0",
                        "name": "subfirst file",
                        "mime_type": "text/plain",
                        "version": "1",
                        "link": "/1/folders/2/files/3"
                    }
                ]
            }
        ]
    },
    {
        "id": "2",
        "position": "0",
        "name": "example",
        "description": "foobar",
        "scope_read": "",
        "scope_edit": "",
        "deleted": null,
        "deleted_by": "0",
        "access": "read",
        "link": "/2",
        "folders": [
            {
                "id": "4",
                "drives_id": "2",
                "position": "0",
                "name": "first",
                "parent_id": "0",
                "deleted": null,
                "deleted_by": "0",
                "link": "/2/folders/4",
                "folders": [],
                "files": [
                    {
                        "id": "4",
                        "folders_id": "4",
                        "uuid": "284b6289-fa4b-41e5-8c37-5ff3c2d8fea6",
                        "deleted": null,
                        "deleted_by": "0",
                        "name": "first file",
                        "mime_type": "text/plain",
                        "version": "1",
                        "link": "/2/folders/4/files/4"
                    },
                    {
                        "id": "5",
                        "folders_id": "4",
                        "uuid": "aedd4702-33b8-40e3-95e9-557a364e0a4b",
                        "deleted": null,
                        "deleted_by": "0",
                        "name": "updated first file",
                        "mime_type": "text/plain",
                        "version": "1",
                        "link": "/2/folders/4/files/5"
                    }
                ]
            },
            {
                "id": "6",
                "drives_id": "2",
                "position": "0",
                "name": "second",
                "parent_id": "0",
                "deleted": null,
                "deleted_by": "0",
                "link": "/2/folders/6",
                "folders": [],
                "files": []
            },
            {
                "id": "5",
                "drives_id": "2",
                "position": "0",
                "name": "subfirst",
                "parent_id": "0",
                "deleted": null,
                "deleted_by": "0",
                "link": "/2/folders/5",
                "folders": [],
                "files": [
                    {
                        "id": "6",
                        "folders_id": "5",
                        "uuid": "bfbef628-2493-47d9-81c9-6d8748a76ed7",
                        "deleted": null,
                        "deleted_by": "0",
                        "name": "subfirst file",
                        "mime_type": "text/plain",
                        "version": "1",
                        "link": "/2/folders/5/files/6"
                    }
                ]
            }
        ]
    },
    {
        "id": "3",
        "position": "0",
        "name": "example",
        "description": "foobar",
        "scope_read": "",
        "scope_edit": "",
        "deleted": null,
        "deleted_by": "0",
        "access": "read",
        "link": "/3",
        "folders": [
            {
                "id": "7",
                "drives_id": "3",
                "position": "0",
                "name": "first",
                "parent_id": "0",
                "deleted": null,
                "deleted_by": "0",
                "link": "/3/folders/7",
                "folders": [],
                "files": [
                    {
                        "id": "7",
                        "folders_id": "7",
                        "uuid": "e3c17231-86ec-4ce3-b0fd-33d2a7bc3d90",
                        "deleted": null,
                        "deleted_by": "0",
                        "name": "first file",
                        "mime_type": "text/plain",
                        "version": "1",
                        "link": "/3/folders/7/files/7"
                    },
                    {
                        "id": "8",
                        "folders_id": "7",
                        "uuid": "58da090c-5321-400b-8f72-6185751464e6",
                        "deleted": null,
                        "deleted_by": "0",
                        "name": "updated first file",
                        "mime_type": "text/plain",
                        "version": "1",
                        "link": "/3/folders/7/files/8"
                    }
                ]
            },
            {
                "id": "9",
                "drives_id": "3",
                "position": "0",
                "name": "second",
                "parent_id": "0",
                "deleted": null,
                "deleted_by": "0",
                "link": "/3/folders/9",
                "folders": [],
                "files": []
            },
            {
                "id": "8",
                "drives_id": "3",
                "position": "0",
                "name": "subfirst",
                "parent_id": "0",
                "deleted": null,
                "deleted_by": "0",
                "link": "/3/folders/8",
                "folders": [
                    {
                        "id": "40",
                        "drives_id": "3",
                        "position": "0",
                        "name": "first",
                        "parent_id": "8",
                        "deleted": null,
                        "deleted_by": "0",
                        "link": "/3/folders/7",
                        "folders": [],
                        "files": [
                            {
                                "id": "70",
                                "folders_id": "7",
                                "uuid": "e3c17231-86ec-4ce3-b0fd-33d2a7bc3d90",
                                "deleted": null,
                                "deleted_by": "0",
                                "name": "first file",
                                "mime_type": "text/plain",
                                "version": "1",
                                "link": "/3/folders/7/files/7"
                            },
                            {
                                "id": "80",
                                "folders_id": "7",
                                "uuid": "58da090c-5321-400b-8f72-6185751464e6",
                                "deleted": null,
                                "deleted_by": "0",
                                "name": "updated first file",
                                "mime_type": "text/plain",
                                "version": "1",
                                "link": "/3/folders/7/files/8"
                            }
                        ]
                    },
                ],
                "files": [
                    {
                        "id": "9",
                        "folders_id": "8",
                        "uuid": "ada5c2a9-3f11-40de-b3c9-53b81271304c",
                        "deleted": null,
                        "deleted_by": "0",
                        "name": "subfirst file",
                        "mime_type": "text/plain",
                        "version": "1",
                        "link": "/3/folders/8/files/9"
                    }
                ]
            }
        ]
    }
]