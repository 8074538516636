import React from 'react';

const DefaultLayout = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Layout/DefaultLayout'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const TicketLayout = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/Layout'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const MyCases = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/List/MyCases'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const WaitingRoom = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/List/WaitingRoom'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const AllCases = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/List/AllCases'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const CountdownWrapp = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./helpers/CountdownWrapp'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const History = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/Overview/History'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Dashboard = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Dashboard/Dashboard'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Invite = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/Invite'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const InviteLayout = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/Invite/InviteLayout'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const WardSelectionForm = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/New/WardSelectionForm'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const WardPatientForm = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/New/WardPatientForm'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const PatientForm = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/New/PatientForm'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const SelectCondition = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/New/SelectCondition'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const FollowupQuestionnaires = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/Overview/FollowupQuestionnaires'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const FailedTriage = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/New/FailedTriage'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const SummaryPayment = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Tickets/New/Payments'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Questionnaires = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Questionnaires/Questionnaires'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Activity = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Activities/Activity'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const ActivityForm = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Activities/ActivityForm'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const EventMeeting = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Activities/EventMeeting'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Activities = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Activities/Activities'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Library = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Libraries/Library'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const LibraryManagement = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Libraries/LibraryManagement'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const LibraryFileForm = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Libraries/FileForm'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const QlikSenseApp = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Statistics/QlikSenseApp'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const EventForm = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Users/MyPages/Calendar/EventForm'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const Meeting = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/Meetings/Meeting'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});
const POA = React.lazy(async () => {
	const [moduleExports] = await Promise.all([import('./components/POA/PoaOverview'), new Promise((resolve) => setTimeout(resolve, 1000))]);
	return moduleExports;
});

export { 
    DefaultLayout, 
    MyCases, 
    SelectCondition, 
    TicketLayout, 
    WaitingRoom,
    AllCases,
    History,
    CountdownWrapp,
    Dashboard,
    Invite,
    InviteLayout,
    WardSelectionForm,
    WardPatientForm,
    PatientForm,
    FollowupQuestionnaires,
    FailedTriage,
    SummaryPayment,
    Questionnaires,
    Activity,
    ActivityForm,
    EventMeeting,
    Activities,
    Library,
    LibraryManagement,
    LibraryFileForm,
    QlikSenseApp,
    EventForm,
    Meeting,
	POA
};
