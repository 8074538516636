import PropTypes from 'prop-types';
import React from 'react';
import { catalogue_structure } from '../../catalogues';
import Grid from '@mui/material/Unstable_Grid2';
import ErrorBoundary from './ErrorBoundary';
import { CircularProgress } from '@mui/material';

const widget = ({ content, component_props, lazy_loading = 1000 }) => {
	const ComponentName = React.lazy(async () => {
		const [moduleExports] = await Promise.all([import('../' + content), new Promise((resolve) => setTimeout(resolve, lazy_loading))]);
		return moduleExports;
	});

	return <ComponentName {...component_props} />;
};

export const CatalogueGrid = ({ catalogue: { widgets = [], ...props } = { widgets: [] } }) => {
	return (
		<Grid container spacing={2} {...props}>
			{widgets.map((item, index) => (
				<Grid key={index} {...item.breakpoints} {...item.sx} {...item.grid_props}>
					<React.Suspense fallback={<CircularProgress size={20} />}>
						{typeof item.content === 'string' ? (
							<ErrorBoundary>{widget(item)}</ErrorBoundary>
						) : (
							<CatalogueGrid catalogue={item.content} />
						)}
					</React.Suspense>
				</Grid>
			))}
		</Grid>
	);
};

CatalogueGrid.propTypes = {
	catalogue: PropTypes.shape(catalogue_structure),
	spacing: PropTypes.number,
};

export default CatalogueGrid;
