import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Button, Grid, FormHelperText, Typography, ImageList, ImageListItem, ListSubheader, ImageListItemBar, IconButton, Box } from '@mui/material';
import { Delete as DeleteIcon, ZoomIn as ZoomInIcon, Info as InfoIcon } from '@mui/icons-material';
import pdf_image from '../../assets/images/pdf.png';
import player_image from '../../assets/images/player_thumbnail.jpeg';
import document_image from '../../assets/images/document_thumbnail.png';
import Player from '../Layout/Player';
import { openPdfFIle } from '../../helpers/functions/functions';
import { Alert } from '@mui/material';
import { closeLightboxAction } from '../Generic/actions/genericActions';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

/** DOCS:
 * props:
 * id
 * onChange
 * initialFiles
 * limitFiles
 */

class ImageDisplayCards extends Component {
	constructor(props) {
		super(props);

		this.state = {
			values: [],
			modal_player: {
				open: false,
				url: '',
			},
		};

		this.lightbox = null;
		this.galleryRef = React.createRef();
    console.log(props)
	}

	handleClearState = () => {
		this.setState({
			values: [],
			modal_player: {
				open: false,
				url: '',
			},
		});
	};

	componentDidMount() {
		if (typeof this.props.initialFiles !== 'undefined' && this.props.initialFiles.length > 0) {
			this.props.initialFiles.forEach((initialFile) => {
				fetch(initialFile)
					.then((resp) => resp.blob())
					.then((blob) => {
						this.handleImageObject(
							blob,
							this.props.lightboxOnly
								? () => {
										if (
											this.props.lightboxOnly &&
											this.props.lightbox.open &&
											this.props.lightbox.image &&
											this.props.initialFiles.length === 1
										) {
											this.lightbox.loadAndOpen(1, {
												gallery: document.querySelector('#' + this.props.id + '-gallery'),
											});
										}
								  }
								: false
						);
					})
					.catch(() => alert('oh no!'));
			});
		}

		if (typeof this.props.onRef !== 'undefined') {
			this.props.onRef(this);
		}

		this.lightbox = new PhotoSwipeLightbox({
			gallery: '#' + this.props.id + '-gallery',
			// gallery: '#' + props.galleryID,
			children: 'a',
			wheelToZoom: true,
			pswpModule: () => import('photoswipe'),
		});

		this.lightbox.addFilter('contentErrorElement', (contentErrorElement, content) => {
			const el = document.createElement('div');
			el.className = 'pswp__error-msg';

			const openVideoFromLightbox = () => {
				this.lightbox.pswp.close();

				this.setState({
					modal_player: {
						open: true,
						url: content.instance.currSlide.data.element.dataset.src,
					},
				});
			};

			const openPdfFromLightbox = (closeOnOpen = false) => {
				if (closeOnOpen) {
					this.lightbox.pswp.close();
				}

				openPdfFIle(content.instance.currSlide.data.element.dataset.src);
			};

			if (
				content.instance.currSlide.data.element.dataset.type.length > 0 &&
				content.instance.currSlide.data.element.dataset.type.split('/')[0] === 'video'
			) {
				el.style.display = 'none';
				el.innerHTML = `<button onclick="${openVideoFromLightbox()}"></button>`;
			} else if (
				content.instance.currSlide.data.element.dataset.type.length > 0 &&
				content.instance.currSlide.data.element.dataset.type.split('/')[0] === 'application' &&
				content.instance.currSlide.data.element.dataset.type.split('/')[1] === 'pdf'
			) {
				if (this.state.values.length > 0) {
					el.innerHTML = `${this.props.translate('lightbox_pdf_no_preview_description')} <a href="${
						this.state.values[content.index].src
					}" target="_blank">${this.props.translate('lightbox_pdf_no_preview_link')}</a>`;

					// el.innerHTML = `No preview for this pdf file, <a href="${
					// 	this.state.values[content.index].src
					// }" target="_blank">click here to open</a>`;
				} else {
					el.style.display = 'none';
					el.innerHTML = `<button onclick="${openPdfFromLightbox(false)}"></button>`;
				}
			} else {
				el.innerHTML = `<a href="${content.instance.currSlide.data.src}" target="_blank">${this.props.translate(
					'lightbox_image_no_preview_description'
				)}</a>`;
				// el.innerHTML = `<a href="${content.instance.currSlide.data.src}" target="_blank">The image #${
				// 	content.slide.index + 1
				// }</a> cannot be loaded</a>`;
			}

			return el;
		});

		this.lightbox.init();

		this.lightbox.on('close', () => {
			if (this.props.lightboxOnly) {
				this.props.closeLightboxAction();
			}
		});

    // this.lightbox.on('beforeOpen', (event) => {
    //   event.preventDefault();
    //   console.log(event)
    //   return false;
    //   const pswp = this.lightbox.pswp;
    // });
    
	}

	componentWillUnmount() {
		if (typeof this.props.onRef !== 'undefined') {
			this.props.onRef(null);
		}

		this.lightbox.destroy();
	}

	handleAddImage = (e, id, onChange) => {
		var file = e.target.files[0];
		if (this.state.values.some((value) => value.name == file.name)) {
			file = new File([file], '(copy ' + this.state.values.length + ')' + file.name, { type: file.type });
		}

		this.handleImageObject(file, (values) => {
			document.getElementsByTagName('INPUT')[this.props.id].value = '';

			onChange(values);
		});
	};

	handleRemoveImage = (file) => {
		var values = this.state.values.filter((value) => value.name !== file.name);

		this.setState({
			values: values,  
		});
	};

	handleImageObject = (file, callback = false) => {
		if (typeof file === 'undefined') {
			return null;
		}

		if (file) {
			
      const reader = new FileReader();

			reader.onload = (e) => {
				const img = new Image();

				img.onload = () => {
					var blob = URL.createObjectURL(file);

					let thumbnail = document_image;

					switch (file.type.split('/')[0]) {
						case 'application':
							if (file.type.split('/')[1] === 'pdf') {
								thumbnail = blob;
							}
							break;
						case 'video':

            if (file.type.split('/')[1] === 'mp4' && this.props.videoResolutionsAllowed) {
              var dataUrl = reader.result;
              let videoEl = document.createElement('video', { id: 'tmp_resolution_test' });
              videoEl.setAttribute("style", "display: none");
              videoEl.src = dataUrl;
              document.body.appendChild(videoEl);
              videoEl.addEventListener('loadedmetadata', (e) => {
                  let w = parseInt(videoEl.videoWidth);
                  let h = parseInt(videoEl.videoHeight);
                  
                  this.props.videoResolutionsAllowed(w, h);

                  videoEl.remove();
              });          
            }

							thumbnail = blob;
							break;
						case 'image':
							thumbnail = blob;
							break;

						default:
							break;
					}

					this.setState(
						{
							values: [
								...this.state.values,
								{ file: file, name: file.name, src: blob, type: file.type, thumbnail, height: img.height, width: img.width },
							],
						},
						() => {
							if (callback) {
								callback(this.state.values.map(({ file }) => file));
							}
						}
					);
				};

				if (file.type.split('/')[0] === 'application' && file.type.split('/')[1] === 'pdf') {
					img.src = pdf_image;
				} else if (file.type.split('/')[0] === 'video') {
					img.src = player_image;
				} else {
					img.src = e.target.result;
				}
			};

			reader.readAsDataURL(file);

		}
	};

	render() {
		const { translate } = this.props;
		return (
			<div>
				<Grid container>
					<Grid item xs={this.props.layout && 12}>
						<input
							name={this.props.id}
							style={{ display: 'none' }}
							accept={this.props.accept ? this.props.accept : 'image/*'}
							id={this.props.id}
							multiple
							type="file"
							onChange={(e) => this.handleAddImage(e, this.props.id, this.props.onChange)}
							disabled={
								(typeof this.props.uploadEnabled !== 'undefined' && !this.props.uploadEnabled) ||
								(typeof this.props.limitFiles !== 'undefined' && this.state.values.length >= this.props.limitFiles)
							}
						/>
						<Grid container>
							<Grid item sm={12}>
								<ImageList id={this.props.id + '-gallery'} variant="masonry" cols={this.props.limitFiles === 1 ? 1 : 3} gap={8}>
									{this.state.values.map((item, index) => (
										<ImageListItem
											component={'a'}
											href={item.thumbnail}
											data-pswp-width={item.width}
											data-pswp-height={item.height}
											data-type={item.type}
											data-src={item.src}
											key={this.props.id + '-gallery' + '-' + index}
										>
											<img
												onClick={() => {
													if (item.type.split('/')[0] === 'application' && item.type.split('/')[1] === 'pdf') {
														openPdfFIle(item.src);
													}
												}}
												src={
													item.type.split('/')[0] === 'application' && item.type.split('/')[1] === 'pdf'
														? `${pdf_image}`
														: item.type.split('/')[0] === 'video'
														? `${player_image}`
														: `${item.thumbnail}`
												}
												srcSet={
													item.type.split('/')[0] === 'application' && item.type.split('/')[1] === 'pdf'
														? `${pdf_image}`
														: item.type.split('/')[0] === 'video'
														? `${player_image}`
														: `${item.thumbnail}`
												}
												alt={item.name}
												loading="lazy"
											/>
											<ImageListItemBar
												sx={{
													background:
														'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
														'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
												}}
												title={item.name}
												position="top"
												actionIcon={
													<IconButton
														onClick={(event) => {
                              // console.log(event)
                              // event.stopPropagation();
                              // event.preventDefault();
															this.handleRemoveImage(item);
														}}
														sx={{ color: 'white' }}
														aria-label={`delete ${item.name}`}
													>
														<DeleteIcon />
													</IconButton>
												}
												actionPosition="right"
											/>
										</ImageListItem>
									))}
								</ImageList>
							</Grid>
						</Grid>
						{typeof this.props.uploadEnabled !== 'undefined' && this.props.uploadEnabled ? (
							<Grid item sm={12}>
								<label for={this.props.id}>
									{this.props.layout ? (
										<ImageUploadCard
											uploadEnabled={typeof this.props.uploadEnabled !== 'undefined' ? this.props.uploadEnabled : true}
											limitFiles={this.props.limitFiles}
											handleAddImage={(e) => this.handleAddImage(e, this.props.id, this.props.onChange)}
											disabled={
												(typeof this.props.uploadEnabled !== 'undefined' && !this.props.uploadEnabled) ||
												(typeof this.props.limitFiles !== 'undefined' && this.state.values.length >= this.props.limitFiles)
											}
										/>
									) : (
										<Button
											variant="contained"
											color="primary"
											component="span"
											disabled={
												(typeof this.props.uploadEnabled !== 'undefined' && !this.props.uploadEnabled) ||
												(typeof this.props.limitFiles !== 'undefined' && this.state.values.length >= this.props.limitFiles)
											}
										>
											{translate('generic_button_upload')}
										</Button>
									)}
								</label>
							</Grid>
						) : null}
					</Grid>
				</Grid>
				{this.props.validate && this.props.invalid && this.props.error ? (
					<FormHelperText error> {this.props.error}</FormHelperText>
				) : this.props.alert ? (
					<Grid item className="image-display-alert-message-grid">
						<Alert className="event-form-info" variant="outlined" severity="info">
							{translate('form_field_file_max_size_allowed') +
								': ' +
								this.props.validate.maxSizeFile +
								' ' +
								translate('form_field_file_type_not_allowed') +
								': .bin'}
						</Alert>
					</Grid>
				) : (
					''
				)}
				<Player
					open={this.state.modal_player.open}
					url={this.state.modal_player.url}
					onClose={() => this.setState({ modal_player: { ...this.props.modal_player, open: false } })}
				/>
			</div>
		);
	}
}

const ImageUploadCard = ({ uploadEnabled, limitFiles, handleAddImage, disabled }) => {
	return (
		<Box sx={{ backgroundColor: '#E6F0F9', borderRadius: '5px', padding: '16px', textAlign: 'center', cursor: 'pointer' }}>
			<input
				name="image-upload"
				style={{ display: 'none' }}
				accept="image/*"
				id="image-upload"
				multiple
				type="file"
				onChange={handleAddImage}
				disabled={disabled}
			/>
			<label htmlFor="image-upload">
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<AddAPhotoIcon fontSize="large" />
					<Typography>Take Photo</Typography>
				</div>
			</label>
		</Box>
	);
};

const mapStateToProps = (state) => ({
	config: state.config,
	login: state.login,
	translate: getTranslate(state.localize),
	lightbox: state.generic.lightbox,
});

export default connect(mapStateToProps, { closeLightboxAction })(ImageDisplayCards);
