import WIDGETS from './widgets';

export default {
	spacing: 2,
	widgets: [
		{
			content: WIDGETS.PAGE_TITLE,
			breakpoints: { xs: 12 },
			lazy_loading: 0,
			component_props: {
				translations: { title: 'ticket_overview_title' },
				redux: { subtitle: 'tickets.ticket.condition.name' },
			},
		},
		{
			content: WIDGETS.UPDATEWEIGHT,
			breakpoints: { xs: 12, sm: 6 },
			lazy_loading: 0,
			component_props: { redux: { patient_user: 'tickets.ticket.patient' }, hide_title: true },
		},
		{
			content: {
				spacing: 0,
				widgets: [
					{
						content: WIDGETS.DAILY_LOGS_BUTTONS,
						breakpoints: { xs: 12 },
						lazy_loading: 0,
						component_props: { redux: { user_id: 'tickets.ticket.patient.id' } },
						grid_props: {
							// mdOffset: 'auto',
						},
					},
					{
						content: WIDGETS.WEIGHT,
						breakpoints: { xs: 12 },
						lazy_loading: 0,
						component_props: { redux: { user_id: 'tickets.ticket.patient.id' }, hide_title: true },
					},
					{
						content: WIDGETS.ACTIVITYLIST,
						breakpoints: { xs: 12 },
						lazy_loading: 0,
						component_props: { redux: { user_id: 'tickets.ticket.patient.id' }, hide_title: true },
					},
				],
			},
			breakpoints: { xs: 12, sm: 6 },
			lazy_loading: 0,
			component_props: {
				translations: { title: 'ticket_overview_title' },
				redux: { subtitle: 'tickets.ticket.condition.name' },
			},
			props: {
				rowSpacing: 2,
			},
		},

		{ content: WIDGETS.TICKET_OVERVIEW, breakpoints: { xs: 12 }, lazy_loading: 0 },
	],
};
