export const SET_CHANNEL = 'SET_CHANNEL';
export const SET_CHANNEL_HISTORY = 'SET_CHANNEL_HISTORY';
export const SEND_CHANNEL_MESSAGE = 'SEND_CHANNEL_MESSAGE';
export const FILTER_CHANNEL_MESSAGES = 'FILTER_CHANNEL_MESSAGES';
export const CLEAR_CHANNEL = 'CLEAR_CHANNEL';
export const SET_CHANNEL_PARTICIPANTS = 'SET_CHANNEL_PARTICIPANTS';
export const SET_CHANNEL_NEW_MESSAGE = 'SET_CHANNEL_NEW_MESSAGE';
export const SET_READ_NOTIFICATIONS   = 'SET_READ_NOTIFICATIONS';

export const SUBSCRIBE_CHANNEL   = 'SUBSCRIBE_CHANNEL';
export const UNSUBSCRIBE_CHANNEL   = 'UNSUBSCRIBE_CHANNEL';
export const SET_WS_CHANNEL_HISTORY   = 'SET_WS_CHANNEL_HISTORY';
export const PUBLISH   = 'PUBLISH';
export const MODIFY   = 'MODIFY';
export const DISCONNECT   = 'DISCONNECT';
export const SET_CHANNEL_CALLBACK   = 'SET_CHANNEL_CALLBACK';
export const SET_CONNECTION_TOKEN   = 'SET_CONNECTION_TOKEN';
export const PARTICIPANT_SUBSCRIBED   = 'PARTICIPANT_SUBSCRIBED';
export const PARTICIPANT_UNSUBSCRIBED   = 'PARTICIPANT_UNSUBSCRIBED';
export const SET_CHANNELS   = 'SET_CHANNELS';
