import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	setChannelAction,
	subscribeChannelAction,
	unsubscribeChannelAction,
	setChannelHistoryAction,
	publishChannelAction,
	setWsChannelHistoryAction,
	sendChannelMessageAction,
	clearChannelAction,
	setChannelParticipantsAction,
	setChannelNewMessagesAction,
	setReadTicketNotificationsAction,
	disconnectAction,
	participantSubscribedAction,
	participantUnsubscribedAction,
	modifyChannelAction,
} from './actions/websocketsActions';
import { fetchTicketFollowupQuestionnaireAction, fetchTicketAction } from '../Tickets/actions/ticketsActions';
import { parseDateUTC } from '../../helpers/functions/functions';
import soundfile from '../../assets/sounds/mention-alert.mp3';
import moment from 'moment';
import ErrorBoundary from '../Layout/ErrorBoundary';
import axios from 'axios';

export const SocketContext = React.createContext();

/** Connects to a websocket channel on ws://<host>/ws
 * Attempts to get a short lived token from the api to use as authentication
 * when connecting to the websocket channel and then proceeds try to connect.
 *
 * Tokens are very short lived so a new one should be fetched if you need to
 * reconnect.
 */
class SocketProvider extends Component {
	constructor() {
		super();
		this.state = {
			ws: null,
			token: null,
			attempts: 0,
			subscribing: [],
			unsubscribing: [],
		};

		this.audio = new Audio(soundfile);
	}

	componentDidMount() {
		// console.log('MOUNT');
		// console.log('TOKEN - ' + this.props.token);
		if (this.props.token) {
			// console.log('----------- MOUNT CONNECT -----------');
			this.connect();
		}
	}

	componentDidUpdate(prevProps) {
		// console.log('UPDATE');
		// console.log('STATE WS - ' + this.state.ws);
		// console.log('PREV TOKEN - ' + prevProps.token);
		// console.log('PROPS TOKEN - ' + this.props.token);
		if (!this.state.ws && prevProps.token === null && this.props.token) {
			// console.log('----------- UPDATE CONNECT -----------');
			this.connect();
		} else if (this.state.ws && (!this.props.token || (prevProps.login && !this.props.login))) {
			// console.log('----------- UPDATE CLOSE -----------');
			this.state.ws.close(4005);
		}
	}

	componentWillUnmount() {
		// console.log('UNMOUNT');
		if (this.state.ws !== null) {
			// console.log('----------- UNMOUNT CLOSE -----------');
			this.state.ws.close(4005);
		}
	}

	timeout = 250; // Initial timeout duration as a class variable

	/**
	 * @function connect
	 * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
	 */
	connect = () => {
		var ws = new WebSocket(this.props.config.websocket_protocol + `://` + this.props.config.websockethost + '?token=' + this.props.token);

		let that = this; // cache the this
		var connectInterval;

		// websocket onopen event listener
		ws.onopen = () => {
			var connection_date = new Date();
			that.setState({
				connection_date,
				attempts: 0,
				ws: ws,
			});

			that.timeout = 250; // reset timer to 250 on open of websocket connection
			clearTimeout(connectInterval); // clear Interval on on open of websocket connection
		};

		// websocket onclose event listener
		ws.onclose = (e) => {
			console.log('close?');
			this.setState(
				{
					ws: null,
				},
				() => {
					this.props.clearChannelAction(this.props.channel_name);
					this.props.disconnectAction();
				}
			);

			if (e.code !== 4005) {
				that.timeout = that.timeout + that.timeout; //increment retry interval
				connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
			}
		};

		// websocket onerror event listener
		ws.onerror = (err) => {
			ws.close();
		};

		ws.onmessage = (msg) => {
			console.log('on message');

			var data = JSON.parse(msg.data);
			console.log(data);
			if (data.type === 'subscribe' && data.sender.id == this.props.user.id) {
				this.props.subscribeChannelAction(
					data.channel,
					data.created,
					// moment(data.created).format('YYYY-MM-DD HH:mm:ss'),
					data.history,
					data.participants,
					data.ref_type,
					data.ref_id,
					data.title,
					this.setSubscribed
				);
			} else if (data.type === 'subscribe' && data.sender.id != this.props.user.id) {
				this.props.participantSubscribedAction(data.channel, data.sender);
			} else if (data.type === 'unsubscribe' && data.sender.id == this.props.user.id) {
				this.props.unsubscribeChannelAction(data.channel, this.setUnsubscribed);
			} else if (data.type === 'unsubscribe' && data.sender.id != this.props.user.id) {
				this.props.participantUnsubscribedAction(data.channel, data.sender);
			}

			if (data.type === 'publish') {
				this.props.publishChannelAction(data);

				if (this.props.websockets.channels[data.channel].callback) {
					this.props.websockets.channels[data.channel].callback();
				}
			}

			if (data.type === 'modifymessage') {
				this.props.modifyChannelAction(data);
			}

			// if (this.props.handleFilterMessages) {
			// 	if (this.props.handleFilterMessages(data)) {
			// 		// this.props.sendChannelMessageAction(this.props.channel_name, data, this.props.pushMessageToTop);
			//     console.log(data)
			//     this.props.publishChannelAction(data);
			// 	}
			// 	if (data.type === 'readmark' || data.type === 'downloadmark') {
			// 		this.props.setReadTicketNotificationsAction(
			// 			this.props.channel_name,
			// 			data.origin_type,
			// 			data.origin_id,
			// 			data.ref_type,
			// 			data.ref_id
			// 		);
			// 	}
			// } else {
			//   this.props.publishChannelAction(data);
			// 	// this.props.sendChannelMessageAction(this.props.channel_name, data, this.props.pushMessageToTop);
			// 	if (data.type === 'readmark' || data.type === 'downloadmark') {
			// 		this.props.setReadTicketNotificationsAction(
			// 			this.props.channel_name,
			// 			data.origin_type,
			// 			data.origin_id,
			// 			data.ref_type,
			// 			data.ref_id
			// 		);
			// 	}
			// }
		};

		// ws.onmessage = (msg) => {
		// 	console.log('message');
		// 	var data = JSON.parse(msg.data);

		// 	if (data.type === 'subscribe') {
		// 		this.props.subscribeChannelAction(data.channel, data.created);
		// 	}

		// 	if (data.type === 'unsubscribe') {
		// 		this.props.unsubscribeChannelAction(data.channel, data.created);
		// 	}

		// 	if (data.type === 'welcome') {
		// 		this.props.setWsChannelHistoryAction(data.channel, data.history, data.participants);
		// 	}

		// 	if (data.type === 'publish') {
		// 		this.props.publishChannelAction(data.channel, data.message, data.created, data.sender);

		//     if (this.props.websockets[data.channel].callback) {
		//       this.props.websockets[data.channel].callback();
		//     }
		// 	}

		// 	if (data.created) {
		// 		data.created = moment(data.created).format('YYYY-MM-DD HH:mm:ss');
		// 	}

		// 	if (data.type === 'history' && !this.props.skip_history) {
		// 		if (data.message && data.message.length > 0) {
		// 			data.message.forEach((message) => {
		// 				if (message.created) {
		// 					message.created = moment(message.created).format('YYYY-MM-DD HH:mm:ss');
		// 				}
		// 			});
		// 		}
		// 		if (this.props.handleFilterMessages && Array.isArray(data.message)) {
		// 			data.message = data.message.filter(this.props.handleFilterMessages);
		// 		}
		// 		this.props.setChannelHistoryAction(this.props.channel_name, data.message);
		// 	} else if (data.type === 'participants') {
		// 		this.props.setChannelParticipantsAction(this.props.channel_name, data.message);
		// 	} else if (!this.props.skip_history) {
		// 		if (this.props.handleFilterMessages) {
		// 			if (this.props.handleFilterMessages(data)) {
		// 				this.props.sendChannelMessageAction(this.props.channel_name, data, this.props.pushMessageToTop);
		// 			}
		// 			if (data.type === 'readmark' || data.type === 'downloadmark') {
		// 				this.props.setReadTicketNotificationsAction(
		// 					this.props.channel_name,
		// 					data.origin_type,
		// 					data.origin_id,
		// 					data.ref_type,
		// 					data.ref_id
		// 				);
		// 			}
		// 		} else {
		// 			this.props.sendChannelMessageAction(this.props.channel_name, data, this.props.pushMessageToTop);
		// 			if (data.type === 'readmark' || data.type === 'downloadmark') {
		// 				this.props.setReadTicketNotificationsAction(
		// 					this.props.channel_name,
		// 					data.origin_type,
		// 					data.origin_id,
		// 					data.ref_type,
		// 					data.ref_id
		// 				);
		// 			}
		// 		}
		// 		if (
		// 			this.state.connection_date !== null &&
		// 			data.automatic === '0' &&
		// 			data.sender_id !== this.props.user.id &&
		// 			typeof data.created !== 'undefined' &&
		// 			parseDateUTC(data.created) > this.state.connection_date
		// 		) {
		// 			if (
		// 				this.props.mentions &&
		// 				data.users_mentions &&
		// 				Array.isArray(data.users_mentions) &&
		// 				data.users_mentions.includes(this.props.user.id)
		// 			) {
		// 				this.props.setChannelNewMessagesAction(this.props.channel_name, true);
		// 				this.audio.play();
		// 			} else {
		// 				this.props.setChannelNewMessagesAction(this.props.channel_name, false);
		// 			}
		// 		}
		// 		// if (data) {
		// 		// 	data.message.includes(`<span id="chat-user-mention" data-id="${this.props.user.id}">`)
		// 		// }
		// 		if (this.props.handleSendMessage) this.props.handleSendMessage(data);
		// 	}
		// };
	};

	/**
	 * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
	 */
	check = () => {
		const { ws } = this.state;
		// if (!ws || ws.readyState === WebSocket.CLOSED) {console.log('re connect?'); this.preconnect();} //check if websocket instance is closed, if so call `connect` function.
	};

	setSubscribed = (channel_name) => {
		this.setState({
			subscribing: this.state.subscribing.filter((name) => name !== channel_name),
		});
	};

	setUnsubscribed = (channel_name) => {
		this.setState({
			subscribing: this.state.subscribing.filter((name) => name !== channel_name),
		});
	};

	subscribe = (channel = null) => {
		const { ws } = this.state;

		if (!this.state.subscribing.includes(channel) && !this.props.websockets.channels[channel]) {
			this.setState(
				{
					subscribing: [...this.state.subscribing, channel],
				},
				() => {
					ws.send(
						JSON.stringify({
							type: 'subscribe',
							channel,
						})
					);
				}
			);
		}
	};

	unsubscribe = (channel = null) => {
		const { ws } = this.state;

		if (!this.state.unsubscribing.includes(channel) && this.props.websockets.channels[channel]) {
			this.setState(
				{
					unsubscribing: [...this.state.unsubscribing, channel],
				},
				() => {
					ws.send(
						JSON.stringify({
							type: 'unsubscribe',
							channel,
						})
					);
				}
			);
		}
	};

	publish = (channel = null, data, callback = false) => {
		const { ws } = this.state;

		ws.send(
			JSON.stringify({
				type: 'publish',
				channel,
				...data,
			})
		);

		if (callback) {
			callback();
		}
	};

	modify = (channel = null, message_id, text = '') => {
		const { ws } = this.state;

		ws.send(
			JSON.stringify({
				type: 'modifymessage',
				channel,
				message_id,
				text,
			})
		);
	};

	render() {
		return (
			<SocketContext.Provider
				value={{ ws: this.state.ws, subscribe: this.subscribe, unsubscribe: this.unsubscribe, publish: this.publish, modify: this.modify }}
			>
				{this.props.children}
			</SocketContext.Provider>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	user: state.users.whoami,
	config: state.config,
	timezone: state.users.whoami.timezone,
	websockets: state.websockets,
	token: state.websockets.token,
	login: state.login.login,
});

export default connect(mapStateToProps, {
	setChannelAction,
	subscribeChannelAction,
	unsubscribeChannelAction,
	publishChannelAction,
	setChannelHistoryAction,
	setWsChannelHistoryAction,
	sendChannelMessageAction,
	clearChannelAction,
	fetchTicketFollowupQuestionnaireAction,
	fetchTicketAction,
	setChannelParticipantsAction,
	setChannelNewMessagesAction,
	setReadTicketNotificationsAction,
	disconnectAction,
	participantSubscribedAction,
	participantUnsubscribedAction,
	modifyChannelAction,
})(SocketProvider);

SocketProvider.propTypes = {
	channel_name: PropTypes.string.isRequired,
	channel: PropTypes.string.isRequired,
	handleSendMessage: PropTypes.func,
	customFirstMessage: PropTypes.func,
	handleFilterMessages: PropTypes.func,
	token: PropTypes.string.isRequired,
};
