export default {
	AGENDA: 'Dashboard/AgendaWidget',
	ACTIVITIES: 'Dashboard/ActivitiesWidget',
	CASES: 'Dashboard/CasesWidget',
	APPOINTMENTS: 'Dashboard/AppointmentsWidget',
	PROFILE: 'Dashboard/ProfileWidget',
	NAVIGATION: 'Generic/Navigation/Links',
	LOGOUT: 'Generic/Navigation/Logout',
	GOBACKNAVIGATION: 'Generic/Navigation/GoBack',
	UNITS: 'Generic/Navigation/Units',
	TEST: 'Generic/V2__Test',
	CHAT: 'V2__Chat/Chat',
	HEADER: 'V2__Chat/Header',

	/**NHS Widgets Dashboard */
	WELCOME: 'Journals/NHS/WelcomeWidget',
	WEIGHT: 'Journals/NHS/WeightWidget',
	FOOD: 'Journals/NHS/FoodWidget',
	MOOD: 'Journals/NHS/MoodWidget',
	EXERCISE: 'Journals/NHS/ExerciseWidget',
	DAILYREPORT: 'Journals/NHS/DailyReportWidget',
	MEETING: 'Journals/NHS/NextMeetingWidget',
	COMMUNITY: 'Journals/NHS/CommunityWidget',
	LIBRARY: 'Journals/NHS/LibraryWidget',

	/**NHS Journals Widgets */
	/**Smart Goals */
	GOBACKSMARTGOALS: 'Journals/SmartGoals/GoBack',
	SETWEIGHTGOALS: 'Journals/SmartGoals/WeightGoals',
	/**Weight Widgets */
	GOBACKWEIGHT: 'Journals/Weight/GoBack',
	UPDATEWEIGHT: 'Journals/Weight/Weight',
	LOGSWEIGHT: 'Journals/Weight/Logs',
	/**Food Widgets */
	GOBACKFOOD: 'Journals/Food/GoBack',
	UPDATEFOOD: 'Journals/Food/Food',
  LOGSFOOD: 'Journals/Food/FoodLogs',
	/**Mood Widgets */
	GOBACKMOOD: 'Journals/Mood/GoBack',
	UPDATEMOOD: 'Journals/Mood/Mood',
  LOGSMOOD: 'Journals/Mood/MoodLogs',
	/**Exercise Widgets */
	GOBACKEXERCISE: 'Journals/Exercise/GoBack',
	UPDATEEXERCISE: 'Journals/Exercise/Exercise',
  LOGSEXERCISE: 'Journals/Exercise/ExerciseLogs',
	/**Daily Report Widgets */
	GOBACKDAILYREPORT: 'Journals/DailyReport/GoBack',
	DAILYREPORTS: 'Journals/DailyReport/DailyReport',
	DAILYREPORTFOODQUESTIONNAIRE: 'Journals/DailyReport/DailyReportFoodQuestionnaire',
  DAILY_LOGS_BUTTONS: 'Journals/DailyReport/DailyLogsButtons',
	/**Community Widgets */
	GOBACKCOMMUNITY: 'Journals/Community/GoBack',
	COMMUNITYDETAILS: 'Journals/Community/Community',
	ACTIVITYLIST: 'Journals/Community/ActivityList',
  ACTIVITY_SUMMARY: 'Journals/Community/ActivitySummary',
	/**Library Widgets */

	/**Dashboard */
	DASHBOARD: 'Dashboard/Dashboard',

  /**TICKET */
  TICKET_OVERVIEW: 'Tickets/Layout',

  /**GENERIC */
  PAGE_TITLE: 'Generic/PageTitle'
};
