// React core
import React, { Suspense } from 'react';
import { connect } from 'react-redux';

// react-router
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

// Our "pages" main components
import NotFound from './components/404';
import Login from './components/Login/Login';
import CreateNewAccount from './components/Login/CreateNewAccount';
import Welcome from './components/Welcome/Welcome';
import SplashScreen from './components/Layout/SplashScreen';
import * as MyPages from "./components/Users/MyPages";
import { useStyles } from './assets/Theme';
import MeetingLogin from './components/Meetings/MeetingLogin';
import {
	  DefaultLayout, 
    MyCases, 
    SelectCondition, 
    TicketLayout, 
    WaitingRoom,
    AllCases,
    History,
    Dashboard,
    Invite,
    InviteLayout,
    WardSelectionForm,
    WardPatientForm,
    PatientForm,
    FollowupQuestionnaires,
    FailedTriage,
    SummaryPayment,
    Questionnaires,
    Activity,
    ActivityForm,
    EventMeeting,
    Activities,
    Library,
    LibraryManagement,
    LibraryFileForm,
    QlikSenseApp,
    EventForm,
    Meeting,
    CountdownWrapp,
	  POA
} from './RouteComponents';
import CATALOGUES from './catalogues';

function Routes(props) {
	useStyles(props);
	
	return (
		<Suspense fallback={<SplashScreen />}>
			<Switch>
				<Route exact path="/login/:client/password/:token" render={(props) => <Login {...props} platform={'frontoffice'} />} />
				<Route exact path="/login/:client/password/" render={(props) => <Login {...props} platform={'frontoffice'} />} />
				<Route exact path="/login/:client" render={(props) => <Login {...props} platform={'frontoffice'} />} />
				<Route exact path="/login" render={(props) => <Login {...props} platform={'frontoffice'} />} />
				{props.settings.feature.self_registration ? (
					<Route exact path="/register" render={() => <CreateNewAccount />} />
				) : null}
				{(!props.login && props.login.platform !== 'frontoffice') && <Route exact path="/backoffice" render={(props) => <Login {...props} platform={'backoffice'} />} />}
				{!props.is_temporary_user && typeof props.settings.feature !== 'undefined' && props.settings.feature.dashboard ? (
					<DefaultLayout exact path="/dashboard" fullsizeContainer={true} disableMargins={false}  catalogue={props.layouts && props.layouts.dashboard ? CATALOGUES[props.layouts.dashboard.toUpperCase()] : CATALOGUES.DASHBOARD} menu={'dashboard'} />
				) : null}
				{!props.is_temporary_user && <Route exact path="/test" render={() => <DefaultLayout exact path="/test" catalogue={CATALOGUES.TEST} menu={'mycases'} />} />}
				{!props.is_temporary_user && <Route exact path="/patient/:user_id" render={() => <DefaultLayout exact path="/test" catalogue={CATALOGUES.PATIENT} menu={'mycases'} />} />}
				{!props.is_temporary_user && <Route exact path="/mycases" render={() => <DefaultLayout exact path="/mycases" component={MyCases} menu={'mycases'} />} />}
				{!props.is_temporary_user && <Route exact path="/waitingroom" render={() => <DefaultLayout exact path="/waitingroom" component={WaitingRoom} menu={'waitingroom'} />} />}
				{!props.is_temporary_user && <Route exact path="/allcases" render={() => <DefaultLayout exact path="/allcases" component={AllCases} menu={'allcases'} />} />}
				{!props.is_temporary_user && <Route exact path="/case" render={() => <DefaultLayout exact path="/case" catalogue={props.layouts && props.layouts.case ? CATALOGUES[props.layouts.case.toUpperCase()] : CATALOGUES.TICKET} menu={'mycases'} />} />}
				{!props.is_temporary_user && <Route exact path="/history" render={() => <DefaultLayout exact path="/history" component={History} menu={'mycases'} />} />}
				{!props.is_temporary_user && <Route exact path="/conditions" render={() => <DefaultLayout exact path="/conditions" component={SelectCondition} menu={'conditions'} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/:ticket_id/questionnaires/:questionnaire_id" render={() => <DefaultLayout exact path="/case/new/:ticket_id/questionnaires/:questionnaire_id" component={CountdownWrapp} wrapped={Questionnaires} menu={'conditions'} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/plain/:ticket_id/questionnaires/:questionnaire_id" render={() => <DefaultLayout exact path="/case/new/plain/:ticket_id/questionnaires/:questionnaire_id" disableNavigation={true} fullsizeContainer={true} component={CountdownWrapp} wrapped={Questionnaires} menu={'conditions'}  />} />}
				{!props.is_temporary_user && <Route exact path="/followup/tickets/:ticket_id/questionnaire/:questionnaire_id" render={() => <DefaultLayout exact path="/followup/tickets/:ticket_id/questionnaire/:questionnaire_id" component={FollowupQuestionnaires} menu={'conditions'} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/:ticket_id/payment" render={() => <DefaultLayout exact path="/case/new/:ticket_id/payment" component={CountdownWrapp} wrapped={SummaryPayment} menu={'conditions'} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/triage/failed" render={() => <DefaultLayout exact path="/case/new/triage/failed" component={FailedTriage} menu={'conditions'} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/patient/data" render={() => <DefaultLayout exact path="/case/new/patient/data" component={PatientForm} menu={'conditions'} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/ward/ssn" render={() => <DefaultLayout exact path="/case/new/ward/ssn" component={WardSelectionForm} menu={'conditions'} />} />}
				{!props.is_temporary_user && <Route exact path="/case/new/ward/data" render={() => <DefaultLayout exact path="/case/new/ward/data" component={WardPatientForm} menu={'conditions'} />} />}
				{!props.is_temporary_user && props.scopes.includes('navigation.mypages') && <Route exact path="/mypages" render={() => <DefaultLayout exact path="/mypages" component={MyPages.MyPages} menu={'mypages'} />} />}
				{!props.is_temporary_user &&  props.scopes.includes('users.edit') && <Route exact path="/myprofile" render={() => <DefaultLayout exact path="/myprofile" component={MyPages.MyProfile} menu={'mypages'} />} />}
				{!props.is_temporary_user && <Route exact path="/password" render={() => <DefaultLayout exact path="/password" component={MyPages.Password} menu={'mypages'} />} />}
				{!props.is_temporary_user && <Route exact path="/mytexts" render={() => <DefaultLayout exact path="/mytexts" component={MyPages.MyTexts} menu={'mypages'} />} />}
				{!props.is_temporary_user && <Route exact path="/filesforpatients" render={() => <DefaultLayout exact path="/filesforpatients" component={MyPages.FilesForPatients} menu={'mypages'} />} />}
				{!props.is_temporary_user && <Route exact path="/privacypolicy" render={() => <DefaultLayout exact path="/privacypolicy" component={MyPages.PrivacyPolicy} menu={'mypages'} />} />}
				{!props.is_temporary_user && props.scopes.includes('calendar') && <Route exact path="/mycalendar" render={() => <DefaultLayout fullsizeContainer={true} exact path="/mycalendar" component={MyPages.MyCalendar} menu={'mycalendar'} />} />}
				{!props.is_temporary_user && <Route exact path="/invite" render={() => <DefaultLayout exact path="/invite" component={props.settings?.tickets?.invite_form === 'enhanced' ? InviteLayout : Invite} menu={'invite'} />} />}
				{!props.is_temporary_user && props.settings.feature.grants && props.scopes.includes('customer') && <Route exact path="/poa" render={() => <DefaultLayout exact path="/poa" component={POA} menu={'mypages'} />} />}
				{!props.is_temporary_user && props.settings.feature.activities && <Route exact path="/activities" render={() => <DefaultLayout exact path="/activities" component={Activities} menu={'activities'} />} />}
				{!props.is_temporary_user && props.settings.feature.qliksense && <Route exact path="/qliksense" render={() => <DefaultLayout fullsizeContainer={true} disableMargins={true} disableGutters={true} exact path="/qliksense" component={QlikSenseApp} menu={'dashboard'} />} />}
				{!props.is_temporary_user && props.settings.feature.activities && props.scopes.includes('practitioner') && <Route exact path="/activity/new" render={() => <DefaultLayout exact path="/activity/new" component={ActivityForm} menu={'activities'} />} />}
				{!props.is_temporary_user && props.settings.feature.activities && props.scopes.includes('practitioner') && <Route exact path="/activity/:id/edit" render={() => <DefaultLayout exact path="/activity/:id/edit" component={ActivityForm} menu={'activities'} />} />}
				{!props.is_temporary_user && props.settings.feature.activities && <Route exact path="/activity/:id/event/:event_id" render={() => <DefaultLayout backgroundColorClass={'default-layout-background-color-grey-900'} fullsizeContainer={true} disableMargins={true} disableGutters={true} exact path="/activity/:id/event/:event_id" component={EventMeeting} menu={'activities'} />} />}
				{!props.is_temporary_user && props.settings.feature.activities && <Route exact path="/activity/:id" render={() => <DefaultLayout exact path="/activity" component={Activity} menu={'activities'} />} />}
				{!props.is_temporary_user && props.settings.feature.libraries && props.scopes.includes('library') && <Route exact path="/library/edit/file" render={() => <DefaultLayout exact path="/library/edit/file" component={LibraryFileForm} menu={'library'} />} />}
				{!props.is_temporary_user && props.settings.feature.libraries && props.scopes.includes('library') && props.scopes.includes('libraries.edit') && <Route exact path="/library/edit" render={() => <DefaultLayout exact path="/library/edit" component={LibraryManagement} menu={'library'} />} />}
				{!props.is_temporary_user && props.settings.feature.libraries && props.scopes.includes('library') && <Route exact path="/library" render={() => <DefaultLayout exact path="/library" component={Library} menu={'library'} />} />}
				{!props.is_temporary_user && props.scopes.includes('calendar') && <Route exact path="/event/:event_id" render={() => <DefaultLayout exact path="/event/:event_id" component={EventForm} menu={'mycalendar'} />} />}
				{!props.is_temporary_user && props.scopes.includes('calendar') && <Route exact path="/event" render={() => <DefaultLayout exact path="/event" component={EventForm} menu={'mycalendar'} />} />}
				{props.login && props.settings.feature.meetings && <Route exact path="/meetings/:token" render={() => <DefaultLayout exact path="/meetings/:token" backgroundColorClass={'default-layout-background-color-grey-900'} fullsizeContainer={true} disableMargins={true} disableGutters={true} component={Meeting} />} />}
				{props.login && props.settings.feature.meetings && <Route exact path="/meetings/" render={() => <DefaultLayout exact path="/meetings/" backgroundColorClass={'default-layout-background-color-grey-900'} fullsizeContainer={true} disableMargins={true} disableGutters={true} component={Meeting} />} />}
				{!props.login && props.settings.feature.meetings && <Route exact path="/meetings/:token" render={() => <MeetingLogin />} />}
				{!props.login && props.settings.feature.meetings && <Route exact path="/meetings/" render={() => <MeetingLogin />} />}
				<Route exact path="/" component={Welcome} />
				<Route exact path="/404" component={NotFound} />
				<Redirect 
          to={{
            pathname: props.login ? "/404" : "/login",
            search: props.login ? "" : props.location.search,
            state: { referrer: props.login ? undefined : props.location.pathname }
          }}
        />
			</Switch>
		</Suspense>
	);
}

const mapStateToProps = (state) => ({
	settings: state.settings,
	scopes: state.users.whoami.scopes ? state.users.whoami.scopes : [],
	login: state.login.login,
	is_temporary_user: state.users.whoami.status == 5 || state.users.whoami.status == 6,
  layouts: state.generic.layouts
});

export default connect(mapStateToProps, null)(withRouter(Routes));
