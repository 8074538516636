import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import { Badge, IconButton, Button } from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import SocketProvider, { SocketContext } from '../Chat/SocketProvider';
import { NOTIFICATIONS } from '../../helpers/webSocketChannels';
import { filterChannelMessagesAction } from '../Chat/actions/websocketsActions';
import { displayNotificationsModalAction } from './actions/genericActions';

class Notifications extends Component {
	static contextType = SocketContext;

	componentDidMount() {
		if (this.context.ws && this.context.subscribe && this.props.whoami.channel) {
			this.context.subscribe(this.props.whoami.channel.name);
		}
	}

	handleFilterMessages = (data) => {
		return data.type === 'publish' && data.link_type !== 'read'; //Check this
	};

	handleSendMessage = (data) => {
		if (data.type === 'publish' && data.unread == '1') {
			var bellList = document.getElementById('listBell_' + data.ticket_id);
			var bellNav = document.getElementById('navBell');

			if (bellList) {
				bellList.classList.remove('notification-bell-hidden');
				bellList.classList.add('notify');
				setTimeout(() => {
					bellList.classList.remove('notify');
				}, 500);
			}
			if (bellNav) {
				bellNav.classList.add('notify');
				setTimeout(() => {
					bellNav.classList.add('notify');
				}, 500);
			}
		} else if ((data.type === 'readmark' || data.type === 'downloadmark') && data.ticket_id) {
			var bellList = document.getElementById('listBell_' + data.ticket_id);

			if (bellList !== null) {
				bellList.classList.add('notification-bell-hidden');
			}
		}
	};

	render() {
		return (
			// <SocketProvider
			// 	channel_name={NOTIFICATIONS}
			// 	channel={typeof this.props.channel !== 'undefined' ? this.props.channel.name : ''}
			// 	handleSendMessage={this.handleSendMessage}
			// 	handleFilterMessages={this.handleFilterMessages}
			// 	pushMessageToTop={true}
			// >
			<React.Fragment>
				<IconButton
					size={'small'}
					color="primary"
					aria-controls={this.props.notificationsId}
					aria-haspopup="true"
					onClick={() => this.props.displayNotificationsModalAction(true)}
					// disabled={this.props.messages.length === 0}
				>
					<Badge badgeContent={this.props.messages.length} color="error">
						<NotificationsIcon id={'navBell'} color="primary" />
					</Badge>
				</IconButton>
			</React.Fragment>
			// </SocketProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	messages: state.websockets.channels && state.websockets.channels[state.users.whoami.channel.name]
		? state.users.whoami.scopes.includes('lists.cross_client')
			? state.websockets.channels[state.users.whoami.channel.name].messages.filter((message) => message.unread == '1')
			: state.websockets.channels[state.users.whoami.channel.name].messages.filter(
					(msg) => msg.ticket_client_id == state.users.whoami.current_client && msg.unread == '1'
			  )
		: [],
	whoami: state.users.whoami,
	config: state.config,
	channel: state.users.whoami.channel,
	translate: getTranslate(state.localize),
	timezone: state.users.whoami.timezone,
});

export default connect(mapStateToProps, { filterChannelMessagesAction, displayNotificationsModalAction })(Notifications);
