import {
	SET_CHANNEL,
	SET_CHANNEL_HISTORY,
	SEND_CHANNEL_MESSAGE,
	FILTER_CHANNEL_MESSAGES,
	CLEAR_CHANNEL,
	SET_CHANNEL_PARTICIPANTS,
	SET_CHANNEL_NEW_MESSAGE,
	SET_READ_NOTIFICATIONS,
	SUBSCRIBE_CHANNEL,
	UNSUBSCRIBE_CHANNEL,
	SET_WS_CHANNEL_HISTORY,
	PUBLISH,
	MODIFY,
	DISCONNECT,
	SET_CHANNEL_CALLBACK,
	SET_CONNECTION_TOKEN,
	PARTICIPANT_SUBSCRIBED,
	PARTICIPANT_UNSUBSCRIBED,
	SET_CHANNELS,
} from '../actions/types';
import { WEB_SOCKET_CHANNELS } from '../../../helpers/webSocketChannels';

const initialState = WEB_SOCKET_CHANNELS.reduce(
	(result, item, index, array) => {
		result[item] = {
			channel: '',
			messages: [],
			connection_date: null,
			participants: [],
			has_history: false,
			new_messages: {
				count: 0,
				mentioned: false,
			},
		};
		return result;
	},
	{
		token: null,
		channels: {},
		available_channels: [],
	}
);

const channelInitialState = {
	messages: [],
	connection_date: new Date(),
	participants: [],
	callback: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_CHANNEL:
			return {
				...state,
				[action.payload.channel_name]: { ...state[action.payload.channel_name], ...action.payload.data },
			};
		case SET_CHANNEL_HISTORY:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: [...state[action.payload.channel_name].messages, ...action.payload.data.messages],
					has_history: true,
				},
			};
		case SEND_CHANNEL_MESSAGE:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: action.payload.pushMessageToTop
						? [
								action.payload.message,
								...state[action.payload.channel_name].messages.filter((message) => message.id !== action.payload.message.id),
						  ]
						: [...state[action.payload.channel_name].messages, action.payload.message],
				},
			};
		case FILTER_CHANNEL_MESSAGES:
			// change this!!
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					messages: state[action.payload.channel_name].messages.filter(
						(message) => !action.payload.messages_to_remove.some((message_to_remove) => message.id == message_to_remove.id)
					),
				},
			};
		case SET_READ_NOTIFICATIONS:
			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...state[action.payload.channel_name],
						messages: state[action.payload.channel_name].messages.map((message) => {
							if (message.origin_type === action.payload.origin_type && message.origin_id === action.payload.origin_id) {
								if (!action.payload.ref_type) {
									message.unread = '0';
									return message;
								}
								if (message.ref_type === action.payload.ref_type) {
									if (!action.payload.ref_id || action.payload.ref_id == '0') {
										message.unread = '0';
										return message;
									}
									if (action.payload.ref_id && message.ref_id === action.payload.ref_id) {
										message.unread = '0';
										return message;
									}
								}
							}
							return message;
						}),
					},
				},
			};
		case CLEAR_CHANNEL:
			return {
				...state,
				[action.payload.channel_name]: initialState[action.payload.channel_name],
			};
		case SET_CHANNEL_PARTICIPANTS:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					participants: action.payload.participants,
				},
			};
		case SET_CHANNEL_NEW_MESSAGE:
			return {
				...state,
				[action.payload.channel_name]: {
					...state[action.payload.channel_name],
					new_messages: action.payload.reset
						? initialState[action.payload.channel_name].new_messages
						: {
								count: state[action.payload.channel_name].new_messages.count + 1,
								...(action.payload.mentioned
									? { mentioned: action.payload.mentioned }
									: { mentioned: state[action.payload.channel_name].new_messages.mentioned }),
						  },
				},
			};

		/**New actions */
		case SUBSCRIBE_CHANNEL:
			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...channelInitialState,
						...{
							messages: action.payload.history,
							participants: action.payload.participants,
							created: action.payload.created,
							ref_type: action.payload.ref_type,
							ref_id: action.payload.ref_id,
							title: action.payload.title,
						},
					},
				},
			};
		case PARTICIPANT_SUBSCRIBED:
			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...state.channels[action.payload.channel],
						participants: state.channels[action.payload.channel].participants.map((participant) =>
							participant.id === action.payload.sender.id ? { ...participant, online: 1 } : participant
						),
					},
				},
			};
		case PARTICIPANT_UNSUBSCRIBED:
			return {
				...state,
				channels: {
					...state.channels,
					[action.payload.channel]: {
						...state.channels[action.payload.channel],
						participants: state.channels[action.payload.channel].participants.map((participant) =>
							participant.id === action.payload.sender.id ? { ...participant, online: 0 } : participant
						),
					},
				},
			};
		case UNSUBSCRIBE_CHANNEL:
			if (state.channels && Object.keys(state.channels).length > 0) {
				const { [action.payload.channel]: all, ...rest } = state.channels;

				return {
					...state,
					channels: rest,
				};
			} else {
				return { ...state };
			}
		case PUBLISH:
			return {
				...state,
				...(state.channels && state.channels[action.payload.channel]
					? {
							channels: {
								...state.channels,
								[action.payload.channel]: {
									...state.channels[action.payload.channel],
									messages: [
										...state.channels[action.payload.channel].messages,
										{
											...action.payload,
											text: action.payload.text,
											created: action.payload.created,
											sender: action.payload.sender,
											notify: action.payload.notify,
										},
									],
								},
							},
					  }
					: {}),
			};
		case MODIFY:
			console.log('MODIFY');
			return {
				...state,
				...(state.channels && state.channels[action.payload.channel]
					? {
							channels: {
								...state.channels,
								[action.payload.channel]: {
									...state.channels[action.payload.channel],
									messages: [
										...state.channels[action.payload.channel].messages.map((message) => {
											if (message.id === action.payload.id) {
												return { ...message, text: action.payload.text, modified: 1, link_type: null, link: null };
											} else {
												return message;
											}
										}),
									],
								},
							},
					  }
					: {}),
			};
		case SET_WS_CHANNEL_HISTORY:
			return {
				...state,
				[action.payload.channel]: {
					messages: action.payload.history,
					participants: action.payload.participants,
				},
			};
		case SET_CHANNEL_CALLBACK:
			return {
				...state,
				[action.payload.channel]: {
					...state[action.payload.channel],
					callback: action.payload.callback,
				},
			};
		case DISCONNECT:
			return {
				...initialState,
			};
		case SET_CONNECTION_TOKEN:
			return {
				...state,
				token: action.payload,
			};
		case SET_CHANNELS:
			return {
				...state,
				available_channels: action.payload,
			};
		default:
			return state;
	}
}
